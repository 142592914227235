import { Link } from "gatsby";
import { css } from "linaria";
import React from "react";
import ReactMarkdown from "react-markdown";
import { theme } from "../Theme/Theme";

export interface ContentRendererProps {
  className?: string;
  source: string;
}

const rendererStyles = css`
  p,
  li {
    margin: 1em 0;
  }

  p {
    @apply font-light;
  }

  * {
    @apply lg:text-lg;
  }

  li {
    @apply pl-2;
  }

  a {
    @apply text-blue-500;
    @apply transition-all;
    @apply duration-300;
    @apply ease-in-out;
    &:hover {
      @apply text-blue-700;
    }
  }

  ol {
    @apply list-decimal;
  }

  img {
    width: 100%;
    margin: 40px 0;
  }

  iframe {
    margin: 40px auto;
    display: flex;
  }

  h2 {
    background: ${theme.color.primaryGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @apply text-xl;
    @apply font-bold;
    @apply mt-10;
  }

  h1 {
    background: ${theme.color.primaryGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @apply text-4xl;
    @apply font-bold;
    @apply mt-10;
  }
`;
const LinkRenderer = (props: any) => {
  const { href, children } = props;

  if (
    href.startsWith("http") ||
    href.startsWith("mailto") ||
    href.startsWith("tel:")
  ) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  return <Link to={href}>{children}</Link>;
};

export const ContentRenderer = (props: ContentRendererProps) => {
  const { className = "", source } = props;

  return (
    <div className={`${rendererStyles} ${className}`}>
      <ReactMarkdown
        source={source}
        // If the url start with either http or https, the image is hosted somewhere else,
        // if hover it starts with / we need to prepend the strapi api url to the url
        transformImageUri={(uri) =>
          uri.startsWith("http")
            ? uri
            : `${process.env.GATSBY_IMAGE_BASE_URL}${uri}`
        }
        renderers={{
          link: LinkRenderer,
        }}
      />
    </div>
  );
};
